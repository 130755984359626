import { render, staticRenderFns } from "./Divider.vue?vue&type=template&id=090aec08&"
import script from "./Divider.vue?vue&type=script&lang=js&"
export * from "./Divider.vue?vue&type=script&lang=js&"
import style0 from "./Divider.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports