<template>
  <div class="rule">
    <div class="line">
      <div /></div>
    <div class="words">
      <span className="px-2 font-weight-lighter small align-self-center">
        {{ text }}
      </span>
    </div>
    <div class="line">
      <div /></div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
}
</script>

<style>
.rule {
    display: table;
}

.rule>div {
    display: table-cell;
    white-space:nowrap;
}

.line>div {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
    height: 1px;
}

.words {
    padding: 0 10px;
}

.line {
    width: 50%;
    vertical-align: middle;
}
</style>
