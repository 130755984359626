<template>
  <div class="component mt-5">
    <b-form-group
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        id="radio-group-2"
        v-model="data.template"
        class="optionContainer"
        :aria-describedby="ariaDescribedby "
        name="radio-sub-component-agent-detail"
      >
        <div class="radioInnerContainer col-12">

          <b-form-radio
            v-for="(style, index) in numOfStyles"
            :key="index"
            :value="++index"
            @input="updateData"
          >
            <span
              :class="{optionSelectedColor: data.template - 1 == index}"
              style="padding-left:25px;"
            >Type {{ index }}</span>
          </b-form-radio>

          <Divider
            class="mt-4"
            text="Or"
          />

          <b-form-radio
            value="c"
            class="col-12 custom"
            @input="updateData"
          >
            <b-card
              class="customContainer col-12"
              :class="{optionSelected : data.template == 'c'}"
            >
              <b-form-file
                v-model="files"
                placeholder="Choose a file or drop it here..."
                accept=".css, .php"
                multiple
                drop-placeholder="Drop file here..."
                @input="e => uploadFile(e)"
              />
            </b-card><span
              :class="{optionSelectedColor: data.template == 'c'}"
              style="padding-left:25px;"
            >Custom</span>
          </b-form-radio>

          <Divider
            class="mt-4"
            text="Additional CSS"
          />
          <b-card v-if="Object.keys(data).length">
            <textarea
              v-model="data.custom.additionalCss"
              class="form-control"
              rows="10"
              @input="updateData"
            />
          </b-card>
        </div>
      </b-form-radio-group></b-form-group>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BFormRadio, BFormRadioGroup, BFormFile,
} from 'bootstrap-vue'
import Divider from './Divider.vue'

export default {
  components: {
    // BSV
    BCard,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormFile,
    Divider,
  },
  props: {
    data: Object,
    custom: [Object, File],
    numOfStyles: Number,
  },
  data() {
    return {
      files: null,
    }
  },
  created() {
    // this.files = this.custom
  },
  methods: {
    uploadFile(event) {
      this.data.template = 'c'

      // const php = []
      // const css = []

      // event.forEach(file => {
      //   const temp = file.name.split('.')
      //   const identifier = temp[1]

      //   if (identifier === 'php' && php.length === 0) {
      //     php.push(file)
      //   } else if (identifier === 'css' && css.length === 0) {
      //     css.push(file)
      //   }
      // })

      // const htmlFile = php[0]
      // const cssFile = css[0]

      this.data.custom.file = []
      // eslint-disable-next-line prefer-destructuring
      this.files = event[0]

      // this.data.listing_archive_custom_file = cssFile
      this.updateData()
    },
    updateData() {
      const temp = {
        style: this.data,
        tab: 'agent_detail',
        custom: this.files,
      }

      this.$emit('update-data', temp)
    },
  },
}
</script>

<style>

</style>
