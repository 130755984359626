<template>
  <div class="page">
    <b-card class="propertyContainer">
      <b-tabs>
        <b-tab title="List Page">
          <ListPage
            :data="templateStyles.listing_archive"
            :custom="listing_archive_custom_file"
            :num-of-styles="numOfStyles.listingArchive"
            @update-data="updateData"
          />
        </b-tab>
        <b-tab title="Detail Page">
          <DetailPage
            :data="templateStyles.listing_detail"
            :custom="listing_detail_custom_file"
            :num-of-styles="numOfStyles.listingDetail"
            @update-data="updateData"
          />
        </b-tab>
        <b-tab title="Map Search">
          <MapSearch
            :data="templateStyles.listing_map"
            :custom="listing_map_custom_file"
            :num-of-styles="numOfStyles.listingMap"
            @update-data="updateData"
          />
        </b-tab>
        <b-tab title="Showcase">
          <Showcase
            :data="templateStyles.listing_showcase"
            :custom="listing_showcase_custom_file"
            :num-of-styles="numOfStyles.listingShowcase"
            @update-data="updateData"
          />
        </b-tab>
        <b-tab title="Search Form">
          <SearchForm
            :data="templateStyles.listing_search"
            :custom="listing_search_custom_file"
            :num-of-styles="numOfStyles.listingSearchForm"
            @update-data="updateData"
          />
        </b-tab>
        <b-tab
          v-if="this.getClient.memberType == 'brokerage'"
          title="Agents List"
        >
          <AgentArchive
            :data="templateStyles.agent_archive"
            :custom="agent_archive_custom_file"
            :num-of-styles="numOfStyles.agentArchive"
            @update-data="updateData"
          />
        </b-tab>

        <b-tab
          v-if="this.getClient.memberType == 'brokerage'"
          title="Agents Detail"
        >
          <AgentDetail
            :data="templateStyles.agent_detail"
            :custom="agent_detail_custom_file"
            :num-of-styles="numOfStyles.agentDetail"
            @update-data="updateData"
          />
        </b-tab>
        <!-- <b-tab title="Agent Detail">
          <SearchForm
            :data="templateStyles.listing_search"
            :custom="listing_search_custom_file"
            @update-data="updateData"
          />
        </b-tab> -->
      </b-tabs>
      <b-button
        variant="outline-primary"
        @click="updateTemplate"
      >
        Update
      </b-button>
      <!-- <div
          class="alert mt-4 "
          :class="{'alert-danger':alert.type=='danger', 'alert-success':alert.type=='success'}"
          role="alert"
        >
          {{ alert.message }}
        </div> -->
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BTabs, BTab,
//   BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ListPage from './components/ListPage.vue'
import DetailPage from './components/DetailPage.vue'
import MapSearch from './components/MapSearch.vue'
import SearchForm from './components/SearchForm.vue'
import Showcase from './components/Showcase.vue'
import AgentArchive from './components/AgentArchive.vue'
import AgentDetail from './components/AgentDetail.vue'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BTabs,
    BTab,
    // BFormInput,
    ListPage,
    DetailPage,
    MapSearch,
    SearchForm,
    Showcase,
    AgentArchive,
    AgentDetail,
  },
  data() {
    return {
      templateStyles: {
        listing_archive: {},
        listing_detail: {},
        listing_map: {},
        listing_showcase: {},
        listing_search: {},
        agent_archive: {},
        agent_detail: {},
      },
      listing_archive_custom_file: {},
      listing_detail_custom_file: {},
      listing_map_custom_file: {},
      listing_showcase_custom_file: {},
      listing_search_custom_file: {},
      agent_archive_custom_file: {},
      agent_detail_custom_file: {},
      numOfStyles: {},
    }
  },
  computed: {
    getClient() {
      return this.$store.state.clientInfo
    },
  },
  created() {
    this.$store.dispatch('website/getStyles').then(response => {
      this.templateStyles = response.data
    }).catch(err => {
      console.error(err)
    })

    this.$store.dispatch('website/getNumberOfStlye').then(response => {
      this.numOfStyles = response.data
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    updateTemplate() {
      const formData = new FormData()

      formData.append('agent_account_id', this.templateStyles.agent_account_id)
      formData.append('agent_user_id', this.templateStyles.agent_user_id)
      formData.append('created_at', this.templateStyles.created_at)
      formData.append('id', this.templateStyles.id)
      formData.append('listing_archive', JSON.stringify(this.templateStyles.listing_archive))
      formData.append('listing_detail', JSON.stringify(this.templateStyles.listing_detail))
      formData.append('listing_map', JSON.stringify(this.templateStyles.listing_map))
      formData.append('listing_search', JSON.stringify(this.templateStyles.listing_search))
      formData.append('listing_showcase', JSON.stringify(this.templateStyles.listing_showcase))
      formData.append('agent_archive', JSON.stringify(this.templateStyles.agent_archive))
      formData.append('agent_detail', JSON.stringify(this.templateStyles.agent_detail))
      formData.append('listing_archive_custom_file', this.listing_archive_custom_file)
      formData.append('listing_detail_custom_file', this.listing_detail_custom_file)
      formData.append('listing_map_custom_file', this.listing_map_custom_file)
      formData.append('listing_search_custom_file', this.listing_search_custom_file)
      formData.append('listing_showcase_custom_file', this.listing_showcase_custom_file)
      formData.append('agent_archive_custom_file', this.agent_archive_custom_file)
      formData.append('agent_detail_custom_file', this.agent_detail_custom_file)
      formData.append('updated_at', this.templateStyles.updated_at)

      this.$store.dispatch('website/updateTemplates', formData).then(response => {
        if (response.code === 200) {
          this.showToast('Style Updated', 'CheckCircleIcon', 'Style has been updated successfully', 'success', 'bottom-right')
        } else {
          this.showToast('Failed', 'AlertCircleIcon', response.message, 'danger', 'bottom-right')
        }
      }).catch(err => {
        console.error(err)
      })
    },
    updateData(data) {
      switch (data.tab) {
        case 'list':
          this.templateStyles.listing_archive = data.style
          this.listing_archive_custom_file = data.custom
          break
        case 'detail':
          this.templateStyles.listing_detail = data.style
          this.listing_detail_custom_file = data.custom
          break
        case 'map':
          this.templateStyles.listing_map = data.style
          this.listing_map_custom_file = data.custom
          break
        case 'search':
          this.templateStyles.listing_search = data.style
          this.listing_search_custom_file = data.custom
          break
        case 'showcase':
          this.templateStyles.listing_showcase = data.style
          this.listing_showcase_custom_file = data.custom
          break
        case 'agent_archive':
          this.templateStyles.agent_archive = data.style
          this.agent_archive_custom_file = data.custom
          break
        case 'agent_detail':
          this.templateStyles.agent_detail = data.style
          this.agent_detail_custom_file = data.custom
          break
        default: console.log('default')
      }
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style>

    .propertyContainer {
        padding: 25px;
    }

    .radioInnerContainer {
        padding: 0 !important;
    }

    .propertyContainer .ul-tabs .vs-tabs--li {
        margin-right: 15px;
        font-family: "Open Sans";
        font-size: 14px;
        color: #616161;
        font-weight: 500;
    }

    .propertyContainer .ul-tabs .activeChild button:focus {
        outline: none !important;
    }

    .propertyContainer .vs-tabs--content {
        padding-left: 1px;
        padding-right: 1px;
    }

    .propertyContainer .optionContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .propertyContainer .custom-control-label {
        position: relative !important;
        cursor: pointer;
    }

    .custom-control-label::before {
        position: absolute;
        top: calc(100% - 18px) !important;
        left: 0;
    }

    .propertyContainer .optionContainer .liContainer {
        margin-top: 20px;
    }

    .propertyContainer .optionContainer .customContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .propertyContainer .imageContainer {
        width: 300px;
        height: 200px;
    }

    .propertyContainer .imageContainer .vs-card--content{
        height: 100%;
    }

    .propertyContainer .imageContainer .optionImage {
        /* max-height: 200px; */
        max-height: 100%;
        margin: auto;
        display: block;
    }

    .propertyContainer .optionChoice .vs-radio--label {
        font-family: "Open Sans";
        font-size: 14px;
        margin-right: 20px;
    }

    .optionSelected {
        border: 3px solid #817EE4 !important;
    }

    .imageContainer, .customContainer {
        border: 3px solid #fff;
    }

    .optionSelectedColor {
        color: #817EE4 !important;
    }

    .optionContainer .customCodeContainer .vs-tabs--li {
        width: 7%;
    }

    .custom .custom-control-label,
    .custom .card-body {
        width: 100%;
    }

    @media only screen and (max-width: 767px) {
        .propertyContainer .optionContainer {
            justify-content: center;
        }

        .optionContainer .customCodeContainer .vs-tabs--li {
            width: 15%;
        }
    }

</style>
